.taxonomy_term--categories--grid {
  position: relative;
  margin-bottom: 3rem;
  border-bottom: 3px solid $yellow;

  a {
    text-decoration: none;
    color: #fff;
    display: block;
    position: relative;

    &:hover,
    &:focus {
      .overlay {
        opacity: 0.5;
      }
      .field-name-field-category-image img {
        @include grayscale_element(0);
      }
      .counts {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  .overlay {
    transition: all 0.3s ease-out;
    opacity: 0;
    background-color: rgba(0,0,0,0.5);
  }

  .field-name-field-category-image img {
    transition: all 0.3s ease-out;
    @include grayscale_element(1);
  }

  h2 {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 1rem;
    background-color: rgba(0,0,0,0.8);
    font-size: 1.6rem;
    text-transform: uppercase;
    @extend .font-smoothing-on;
  }

  .counts {
    opacity: 0;
    transform: scale(0);
    transition: all 0.3s ease-out;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .count {
      font-size: 4rem;
      border-radius: 50%;
      background: #222;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
