.header {
  @extend .font-smoothing-on;
  position: relative;
  margin: 0 0 2rem;
  background-color: rgba(0,0,0,0.85);
  border-top: 3px solid #FFE100;
  border-bottom: none;

  .site-logo {
    width: 90%;
    max-width:675px;
  }

  .header-navbar {
    @include breakpoint(sf) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .block-superfish {
    .block__title {
      @extend .sr-only;
    }
  }

  .region-main-nav {
    .sf-main-menu {
      font-size: 1.4rem;
      font-family: $headings-font-family;
      font-weight: $fw-bold;

      a.sf-depth-1 {
        text-transform: uppercase;
      }
    }
  }
}
