a {
  text-decoration: underline;
  color: $blue;
  &:hover, &:focus {
  }
}

#main-wrapper {
  a:hover, a:focus {
      background-color: lighten($black, 95);
  }
}
