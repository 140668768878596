.taxonomy_term--categories--full {
  .field-name-field-category-image {
    margin-bottom: 1rem;

    img {
      @extend %img-bordered;
    }
  }

  .field-name-field-opening-callout {
    font-size: 1.8rem;
  }

  .field-name-field-opening-text {
    margin-bottom: 2rem;
  }

  .node--resource--teaser {
    //border-bottom: 3px solid #f1f1f1;

    a {
      text-decoration: none;
    }
  }
  h3 {
    background: $black_80;
    //border-top: solid 3px #000;
    color: white;
    font-weight: normal;
    margin-left: -5px;
    padding: 5px;
  }
}
