.view-display-id-page_resources {
  .view-header {
    .view-display-id-attachment_category_opening {
      .views-field-field-opening-text {
        @extend .col-sm-8;
        margin-bottom:20px;
      }
      .views-field-field-opening-callout {
        @extend .col-sm-4;
        img {
          height: auto;
          width: 100%;
        }
      }
    }
  }

  .view-content {
    clear:both;

    .views-row-first {
      .views-field-title {
        border-top: $yellow solid 3pt;
      }
    }

    .views-field-title {
      padding-top: 15px;
        a {
          display: block;
          font-size: 1.25rem;
          margin-top: 5px;
        }
    }
  }
}
