.node--resource--teaser {
  margin-bottom: 1.3rem;
  padding-bottom: 1.3rem;

  h2 {
    margin: 0;
    margin-bottom: 0.5rem;

    i {
      margin-left: 0.75rem;
      color: $black_60;
    }

    .link + .tooltip{
      .tooltip-inner {
        max-width: 100%;
        font-size: 1.2rem;
      }
    }

    .label {
      font-size: 1rem;
      float: right;
      vertical-align: middle;
    }
  }

  .field-name-body {
    @extend .small;
  }
}
