.footer {
  @extend .font-smoothing-on;
  background-color: rgba(0,0,0,0.85);
  border-bottom: 3px solid $yellow;
  border-top: none;
  color: White;
  margin: 2rem 0 0;
  line-height: 1.5em;
  padding: 1em 0 1em 0;
  position: relative;

  a {
    color: White;
    &:hover, &:focus, &:active {
      background: rgba(255,255,255,0.2);
    }
  }
}
