ul, ol {
  color: $black;
  font-family: Arial, san-serif;
  margin-top: 20px;

  li {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
