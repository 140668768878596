#main-wrapper {
  .main-content {
    background-color: #FFF;
    padding: 3rem;
  }

  .page-title {
    margin-top: 0;
  }
}
